<template>
  <section class="marketplace" :class="{ 'marketplace--scrolled': user_scroll_depth !== 0 }">
    <Header />

    <section class="marketplace-content">
      <router-view name="marketplace_content"/>
    </section>

    <Footer />
  </section>
</template>

<script>
import { mapState, mapMutations } from "vuex"
import Footer from "./layout/Footer"
import Header from "./layout/Header"
import translator from "../../Shared/translator"
import { SHARED_STORE } from "../../Shared/constants/other"
import { UPDATE_ENVIRONMENT } from "../../../store"

export default {
  name: "Marketplace",
  components: {
    Footer,
    Header
  },
  data() {
    return {
      user_scroll_depth: 0
    }
  },
  async created() {
    await translator(false, "en", "Marketplace")
    document.body.style.padding = "0"
    this.is_user_on_marketplace(true)
  },
  computed: {
    ...mapState(SHARED_STORE, [
      "is_mobile_device"
    ]),
  },
  mounted() {
    if (!this.is_mobile_device) document.addEventListener(
      "scroll", () => this.user_scroll_depth = window.scrollY, { passive: true }
    )
  },
  methods: {
    ...mapMutations({
      is_user_on_marketplace: UPDATE_ENVIRONMENT
    }),
  }
}
</script>

<style lang="scss">
  @use "../../../styles/_global-constants" as *;
  @use "../../../styles/_marketplace-constants" as *;

  .marketplace {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-areas: "header  header  header"
    "content content content"
    "footer  footer  footer";

    a {
      color: inherit;
    }

    &--scrolled {
      padding-top: $menu-height;
    }

    @media (max-width: $tablet) {
      padding-top: $menu-height;
    }

    &-content {
      grid-area: content;
      min-height: 100vh;
      max-width: $maximum-content-width;
      width: 100%;
      margin: auto;
    }
  }
</style>
