export default {
  about: {
    title: "About",
    links: [
      {
        title: "About us",
        link: "/about/about_us"
      },
      {
        title: "Services",
        link: "/about/services"
      },
      {
        title: "Terms and conditions",
        link: "/legal/terms_and_conditions"
      },
      {
        title: "Blog",
        link: "/about/blog"
      }
    ]
  },
  help: {
    title: "Help & Contact",
    links: [
      {
        title: "Help center",
        link: "/help/help"
      },
      {
        title: "Contact",
        link: "/help/contact"
      },
      {
        title: "Seller information center",
        link: "/help/seller_information"
      }
    ]
  },
  sell: {
    title: "Sell",
    links: [
      {
        title: "Start selling",
        link: "/external/new_seller_registration"
      },
      {
        title: "Learn to sell",
        link: "/selling_information/learn_to_sell"
      },
      {
        title: "Ulsemo group sellers",
        link: "/selling_information/group"
      }
    ]
  },
  buy: {
    title: "Buy",
    links: [
      {
        title: "Registration",
        link: "/login?registration"
      },
      {
        title: "Paypal Buyer protection",
        link: "/external/paypal_buyer_protection"
      },
      {
        title: "Stores",
        link: "/stores"
      }
    ]
  }
}