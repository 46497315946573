<template>
  <section class="marketplace-footer">
    <div class="marketplace-footer__wrapper">
      <section class="marketplace-footer__top">
        <div class="marketplace-footer__left">
          <router-link
            to="/"
            itemprop="logo"
            aria-label="Go to home page"
          >
            <img src="/static/icons/ulsemo-icon.svg" alt="Ulsemo logo">
          </router-link>
          <p>
            Some info about us.
          </p>
          <ul>
            <li class="marketplace-footer__item">
              <a class="marketplace-footer__link" href="https://www.facebook.com/Ulsemo-106605291210423/">
                <facebook_icon />
              </a>
            </li>
            <li class="marketplace-footer__item">
              <a class="marketplace-footer__link" href="https://www.instagram.com/ulsemosoftware/">
                <instagram_icon />
              </a>
            </li>
            <li class="marketplace-footer__item">
              <a class="marketplace-footer__link" href="https://www.twitter.com/ulsemo">
                <twitter_icon />
              </a>
            </li>
          </ul>
        </div>

        <div class="marketplace-footer__right">
          <div
            v-for="({ title, links }, index) in columns"
            :key="index"
            class="marketplace-footer__column"
          >
            <p>{{ title }}</p>
            <router-link
              v-for="({ link, title: linkTitle }, linkIndex) in links"
              :key="`link-${index}-${linkIndex}`"
              :to="link"
              itemprop="link"
              :aria-label="`Go to ${linkTitle} page`"
            >
              {{ linkTitle }}
            </router-link>
          </div>
        </div>
      </section>
      <section class="marketplace-footer__bottom">
        @ 2020 Ulsemo. All rights reserved
      </section>
    </div>
  </section>
</template>

<script>
import facebook_icon from "../../../Shared/components/icon_components/facebook-icon"
import instagram_icon from "../../../Shared/components/icon_components/instagram-icon"
import twitter_icon from "../../../Shared/components/icon_components/twitter-icon"
import page_links from "../../constants/main_page_links"

export default {
  components: {
    facebook_icon,
    instagram_icon,
    twitter_icon
  },
  data() {
    return {
      columns: Object.values(page_links)
    }
  }
};
</script>

<style lang="scss" scoped>
@use "../../../../styles/_global-constants" as *;
@use "../../../../styles/_marketplace-constants" as *;

.marketplace-footer {
  background-color: $brand-color;
  grid-area: footer;
  margin-top: $spacing--medium;

  &__wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    padding: $spacing--extra-large 0;
    max-width: $maximum-width;
    margin: auto;

    @media screen and (max-width: $maximum-width) {
      padding: $spacing--medium $spacing--small;
    }

    @media screen and (max-width: $mobile) {
      padding: $spacing--small $spacing--small;
    }
  }

  &__top {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 2fr;
    grid-gap: $spacing--medium;
    grid-template-areas: "footer-left footer-right";
    padding-bottom: $spacing--small;
    margin-bottom: $spacing--small;
    border-bottom: 1px solid $grey--light;
    align-items: top;
    font-size: 15px;
    color: $grey--dark;

    @media screen and (max-width: $tablet--small) {
      grid-template-columns: 1fr;
      grid-template-areas: "footer-left" "footer-right";
    }

    & img {
      width: $medium-icon;
      height: $medium-icon;
    }
  }

  &__left {
    grid-area: footer-left;

    p {
      margin: $spacing--medium 0;
    }

    ul {
      display: block;
      padding: 0;
      margin: 0;
      list-style: none;

      li {
        display: inline-block;
        width: $small-icon;
        height: $small-icon;
        margin-right: $spacing--small;
      }
    }
  }

  &__right {
    grid-area: footer-right;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: space-between;

    p {
      margin-top: 0;
      color: $grey--darkest;
      font-weight: bold;
    }

    a {
      display: block;
      color: $grey--dark;
      line-height: 22px;
    }
  }

  &__column {
    margin-right: 20px;
    margin-bottom: 20px;
  }

  &__bottom {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $grey;
    font-size: 13px;
  }
}
</style>
