<template>
  <section class="marketplace-header" :class="{ 'marketplace-header--scrolled': user_scroll_depth !== 0 }">
    <div class="marketplace-header__wrapper" :class="{ 'marketplace-header__wrapper--searching': searching }">
      <div class="marketplace-header__logo">
        <router-link to="/">
          <img src="/static/icons/ulsemo-wide-full-logo.svg" alt="Ulsemo logo">
        </router-link>
      </div>

      <div v-if="!searching" class="marketplace-header__items" @mouseleave="selected_header_id = null">
        <div
          v-for="({ name, link, id }, index) in header_items"
          :key="`link-${index}`"
          class="marketplace-header__item"
          @mouseover="selected_header_id = id"
        >
          <router-link v-if="name" :to="link || `/categories/${id}`">
            {{ name }}
          </router-link>
          <p v-else class="element-loading"/>
        </div>

        <div class="marketplace-header__menu-toggle">
          <mobile_menu_toggle
            :open_mobile_menu="open_mobile_menu"
            @toggle_mobile_menu="open_mobile_menu = !open_mobile_menu"
          />
          <mobile_menu
            :items="show_items ? get_header_children : default_mobile_menu"
            :show_back_button="show_items"
            :show="open_mobile_menu"
            @back="back_button_click"
            @clear_header_state="clear_header_state"
          />
        </div>

        <transition name="slide-animation">
          <div
            v-if="selected_header_id"
            class="marketplace-header__sub-items"
          >
            <div
              v-for="{ id, name, src } in get_header_children"
              :key="`header-child-${id}`"
              class="marketplace-header__sub-item"
              @click="$router.push(`/products/${selected_header_id}/${id}`)"
            >
              <img v-if="src" :src="src" :alt="name">
              {{ name }}
            </div>
          </div>
        </transition>
      </div>

      <div class="search" v-if="searching">
        <input
          type="text"
          name="search"
          id="search"
          :placeholder="$translate('header.search')"
          v-model="query"
          @input="search_products"
          ref="search_input"
        />
        <div v-if="searching && query.length > MIN_SEARCH_CHARACTERS" class="search-results">
          <router-link
            v-for="({ id, categories = [], images, title, variants, _highlightResult, ...rest }, index) in found_products"
            :key="`search-product-${index}`"
            :to="`/products/${categories.join('/')}/${id}`"
            :disabled="!id" 
            :event="id ? 'click' : ''"
          >
            <div
              class="search-result"
              :class="{ 'element-loading': !id }"
            >
              <img v-if="images" :src="get_correct_first_image({ images }) || '/static/backgrounds/default-img.jpg'" :alt="`${title} image`">
              <div v-if="get_correct_translation_value(_highlightResult, 'title', [marketplace_language], true, null, null, null, true)">
                <p v-html="get_correct_translation_value(_highlightResult, 'title', [marketplace_language], true, null, null, null, true)" />
                <p>
                  {{ get_correct_translation_value(_highlightResult, "currency_code", [marketplace_language], true, null, null, null, true) }}
                  {{ get_correct_translation_value(variants[0], "price", [marketplace_language]) }}
                </p>
              </div>
            </div>
          </router-link>
          <p v-if="!found_products.length && query.length > MIN_SEARCH_CHARACTERS">{{ $translate("header.no_products") }}</p>
        </div>
      </div>

      <div class="marketplace-header__buttons">
        <div class="marketplace-header__button" @click="toggle_search">
          <close_icon v-if="searching"/><search_icon v-else />
        </div>
        <div class="marketplace-header__button" @click="$router.push(user ? '/profile' : '/login')">
          <account_icon />
        </div>
        <div
          class="marketplace-header__button marketplace-header__basket"
          @click="$router.push('/basket')"
          @mouseover="$route.path !== '/basket' && toggle_preview_cart([true])"
          @mouseleave="$route.path !== '/basket' && close_preview_basket()"
        >
          <shopping_cart_icon />
          <span v-if="basket_items.length">{{ basket_items.length }}</span>
        </div>
      </div>
      <preview_basket v-if="$route.path !== '/basket'"/>
    </div>
  </section>
</template>

<script>
import { mapMutations, mapState } from "vuex"
import search_icon from "../../../Shared/components/icon_components/search-icon"
import close_icon from "../../../Shared/components/icon_components/close"
import account_icon from "../../../Shared/components/icon_components/account-icon"
import shopping_cart_icon from "../../../Shared/components/icon_components/shopping-cart-icon"
import mobile_menu_toggle from "../../../Shared/components/utils/mobile_menu_toggle"
import { currency_signs, SHARED_STORE } from "../../../Shared/constants/other"
import { get_marketplace_popular_categories } from "../../constants/endpoints"
import { ALL_CATEGORIES_OBJECT, MARKETPLACE, MIN_SEARCH_CHARACTERS } from "../../constants/other"
import mobile_menu from "../../../Shared/components/utils/mobile_menu"
import page_links from "../../constants/main_page_links"
import debounce from "../../../Shared/methods/debounce"
import preview_basket from "../../../Shared/components/utils/basket/preview_basket"
import search_marketplace_products from "../../methods/search_marketplace_products"
import { TOGGLE_PREVIEW_BASKET } from "../../../Shared/stores/Shared/constants"
import get_correct_first_image from "../../../Shared/methods/get_correct_first_image"
import get_correct_translation_value from "../../../Shared/methods/get_correct_translation_value"

const default_found_products = [{},{},{}]

export default {
  components: {
    search_icon,
    close_icon,
    account_icon,
    shopping_cart_icon,
    mobile_menu_toggle,
    mobile_menu,
    preview_basket
  },
  data() {
    return {
      user_scroll_depth: 0,
      open_mobile_menu: false,
      header_items: [{}, {}, {}, {}],
      stored_header_items: [],
      found_products: default_found_products,
      loading_header_items: true,
      searching: false,
      selected_header_id: null,
      show_items: false,
      query: "",
      default_mobile_menu: {},
      MIN_SEARCH_CHARACTERS
    }
  },
  computed: {
    ...mapState(SHARED_STORE, [
      "is_mobile_device",
      "basket_items"
    ]),
    ...mapState(MARKETPLACE, [
      "marketplace_currency",
      "marketplace_language"
    ]),
    ...mapState([
      "user",
    ]),
    currency_sign() {
      return currency_signs[this.marketplace_currency]
    },
    get_header_children() {
      if (this.selected_header_id) return Object.values(this.header_items.find(({ id }) => id === this.selected_header_id).children).map(
        child => ({ ...child, link: child.route || `/products/${this.selected_header_id}/${child.id}` })
      )

      return this.open_mobile_menu ? this.header_items.map(item => ({
          ...item, click: () => this.selected_header_id = item.id
        })) : null
    }
  },
  watch: {
    $route() {
      this.clear_header_state()
      this.stop_searching()
    },
    user(user_data) {
      this.default_mobile_menu = {
        ...this.default_mobile_menu,
        login: {
          title: this.$translate(`header.${user_data ? "profile" : "log_in"}`),
          link: user_data ? "/profile" : "/login"
        }
      }
    }
  },
  async mounted() {
    if (!this.is_mobile_device) document.addEventListener(
      "scroll", () => this.user_scroll_depth = window.scrollY, { passive: true }
    )

    const { data } = await get_marketplace_popular_categories()

    this.default_mobile_menu = {
      categories: {
        title: this.$translate("header.categories_title"),
        click: () => { this.show_items = true; this.header_items = this.stored_header_items },
        items: [{}, {}, {}, {}],
      },
      sell: {
        ...page_links.sell,
        click: () => { this.show_items = true; this.header_items = page_links.sell.links },
      },
      help: {
        ...page_links.help,
        click: () => { this.show_items = true; this.header_items = page_links.help.links },
      },
      login: {
        title: this.$translate(`header.${this.user ? "profile" : "log_in"}`),
        link: this.user ? "/profile" : "/login"
      }
    }
    this.header_items = [
      ...data.slice(0, 3),
      ALL_CATEGORIES_OBJECT
    ]
    this.stored_header_items = [
      ...data,
      ALL_CATEGORIES_OBJECT
    ]
    this.loading_header_items = false
  },
  methods: {
    ...mapMutations(SHARED_STORE, {
      toggle_preview_cart: TOGGLE_PREVIEW_BASKET
    }),
    get_correct_first_image,
    get_correct_translation_value,
    close_preview_basket() {
      // Wait for one second if user moves mouse over preview basket
      setTimeout(() => this.toggle_preview_cart([false]), 300)
    },
    back_button_click() {
      if (this.selected_header_id) this.selected_header_id = null
      else this.show_items = false
    },
    clear_header_state() {
      this.open_mobile_menu = false
      this.selected_header_id = null
      this.show_items = false
    },
    search_products() {
      this.searching = true
      this.found_products = this.query.length > MIN_SEARCH_CHARACTERS ? default_found_products : []

      debounce(async () => {
        if (this.query.length > MIN_SEARCH_CHARACTERS) {
          const products = await search_marketplace_products({ query: this.query })
          this.$nextTick(() => this.found_products = products)
        }
      }, 1000, this)()
    },
    toggle_search() {
      this.searching = !this.searching

      if (!this.searching) this.stop_searching()
      else this.$nextTick(() => this.$refs.search_input.focus())
    },
    stop_searching() {
      this.searching = false
      this.query = ""
      this.found_products = default_found_products
    }
  }
}
</script>

<style lang="scss">
@use "../../../../styles/_global-constants" as *;
@use "../../../../styles/_marketplace-constants" as *;
@use "../../../../styles/element_loading" as *;
@use "../../../../styles/rise_up_effect" as *;
@use "../../../../styles/search" as *;

$logo-width: 100px;
$class: "marketplace-header";

@mixin shrunk-header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 101; // Because of paypal buttons
  box-shadow: 0 2px 6px 0 rgba($pure-black, 0.2);
  border-bottom: none;

  .#{$class}__wrapper {
    $buttons-width: calc(3 * #{$menu-height} + 9px);
    grid-template-columns: $logo-width calc(100% - #{$buttons-width} - #{$logo-width}) $buttons-width;

    &--searching {
      grid-template-areas: "header-logo search header-buttons";

      .search {
        grid-template-rows: $menu-height;

        input {
          line-height: $menu-height;
        }
      }
    }

    @media (max-width: $tablet) {
      $buttons-width: calc(#{$menu-height} + 3px);
      grid-template-columns: $buttons-width calc(100% - #{$buttons-width} - #{$buttons-width}) $buttons-width;
      grid-template-areas: "header-items header-logo header-buttons";
    }
  }

  .#{$class}__button {
    line-height: $menu-height;

    @media (max-width: $tablet) {
      display: none;

      &:last-child {
        display: block;
      }
    }
  }
}

.#{$class} {
  grid-area: header;
  width: 100%;
  border-bottom: 1px solid $grey--light;
  background-color: $pure-white;

  @media (max-width: $maximum-width) {
    padding-left: $spacing--small;
  }
  @media (max-width: $tablet) {
    padding-left: 0;
  }

  &--scrolled {
    @include shrunk-header;
  }

  @media (max-width: $tablet) {
    @include shrunk-header;
  }

  &__wrapper {
   $logo-width: 100px;
   $buttons-width: calc(3 * #{$menu-height--expanded} + 9px);

    position: relative;
    display: grid;
    width: 100%;
    margin: 0 auto;
    max-width: $maximum-width;
    grid-template-columns: $logo-width calc(100% - #{$buttons-width} - #{$logo-width}) $buttons-width;
    grid-template-areas: "header-logo header-items header-buttons";

    &--searching {
      grid-template-areas: "header-logo search header-buttons";

      .search {
        border: none;
        padding: 0 0 0 $spacing--extra-large;
        column-gap: 0;
        grid-template-columns: 100%;
        grid-template-areas: "input" "results";
        grid-template-rows: $menu-height--expanded;

        &:after {
          width: calc(100% - #{$spacing--large});
          background: $brand-color--dark;
          top: 100%;
          left: $spacing--large;
        }

        input {
          line-height: $menu-height--expanded;
          padding: 0;
        }

        a:last-child .element-loading {
          margin: 0;
        }

        &-result {
          height: auto;
          min-height: 50px;
          padding: $spacing--tiny $spacing--small;
          transition: background-color .3s linear;

          &:hover {
            background-color: $grey--light;
          }

          &.element-loading {
            margin: 0 0 1px;
          }

          &s {
            position: absolute;
            top: calc(100% + 1px);
            width: calc(100% + #{$spacing--normal});
            right: 0;
            z-index: 10;
            background-color: $pure-white;
            box-shadow: 0 3px 5px 0 rgba($pure-black, 0.2);
            border-top: 1px solid $grey--light;
          }
        }
      }
    }
  }

  &__logo {
    grid-area: header-logo;
    max-height: $menu-height--expanded;

    @media (max-width: $tablet) {
      text-align: center;
    }

    img {
      width: 100%;
      height: 100%;

      @media (max-width: $tablet) {
        width: 100px;
      }
    }
  }

  &__search {
    grid-area: search;
  }

  &__sub-item {
    position: relative;
    display: inline-block;
    line-height: 50px;
    font-size: $font-size--medium;
    padding: 0 $spacing--tiny;
    background-color: $pure-white;
    border-radius: $border-radius;
    border: 1px solid $grey--lightest;
    margin: 0 $spacing--tiny $spacing--tiny 0;
    cursor: pointer;
    transition: .2s linear;

    &:hover {
      @extend .rise-up-effect;
    }

    img {
      position: absolute;
      left: 0;
      top: 0;
      width: auto;
      height: 100%;
      filter: blur(3px);
      opacity: 0.7;
      z-index: -1;
      object-fit: cover;
    }

    &s {
      position: absolute;
      width: 100%;
      left: 0;
      top: 100%;
      max-width: $maximum-width;
      padding: $spacing--small $spacing--tiny $spacing--tiny $spacing--small;
      background-color: $pure-white;
      z-index: 1;
      border-bottom-left-radius: $border-radius;
      border-bottom-right-radius: $border-radius;
      border-top: 1px solid $grey--light;
      box-shadow: 0 3px 5px 0 rgba($pure-black, 0.2);

      @media (max-width: $tablet) {
        display: none;
        position: absolute;
        z-index: -100;
        opacity: 0;
      }
    }
  }

  &__item {
    display: inline-block;
    margin-right: $spacing--medium;
    font-size: $font-size--medium;
    color: $grey--darkest;
    font-weight: bold;
    word-break: break-word;

    &:last-child {
      margin-right: 0;
    }

    p {
      width: 100px;
      height: 25px;
    }

    &s {
      display: flex;
      align-items: center;
      padding: 12px 15px 0 $spacing--extra-large;

      @media (max-width: $tablet) {
        padding: 0;
      }

      grid-area: header-items;
    }

    @media (max-width: $tablet) {
      display: none;
      position: absolute;
      z-index: -100;
      opacity: 0;
    }
  }

  &__button {
    position: relative;
    display: inline-block;
    max-height: $menu-height--expanded;
    line-height: $menu-height--expanded;
    cursor: pointer;
    text-align: center;
    border-left: 1px solid $grey--light;

    &:last-child {
      border-right: 1px solid $grey--light;
    }

    span {
      position: absolute;
      top: calc(50% + -1px);
      left: calc(50%);
      line-height: $small-icon;
      width: $small-icon;
      height: $small-icon;
      text-align: center;
      background-color: $grey--darkest;
      color: $brand-color;
      font-size: $font-size--small;
      border-radius: 50%;
    }

    svg {
      display: inline-block;
      vertical-align: middle;
      width: 25px;
      height: 25px;
    }

    &s {
      grid-area: header-buttons;
      display: grid;
      grid-template-columns: repeat(3, 1fr);

      @media (max-width: $tablet) {
        grid-template-columns: 1fr;
      }
    }
  }

  &__menu {
    &-toggle {
      display: none;

      @media (max-width: $tablet) {
        display: block;

        .mobile-menu-toggle__x div {
          background-color: $pure-black;
        }
      }
    }
  }

  .slide-animation-enter-active, .slide-animation-leave-active {
    opacity: 1;
    transition: opacity .2s ease-in-out, transform .2s ease-in-out;
  }
  .slide-animation-enter, .slide-animation-leave-to {
    opacity: 0;
    transform: translateY(20px);
  }
}
</style>
