<template functional>
  <svg id="Layer_1" :fill="props.fill" height="24" width="24" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1109.76 864.6"><path d="M630.39,260.93l-18-81.42L455.56,110.83a36,36,0,0,0-28.82,66l123.2,53.8L647.2,669.91l8.41,37.7A35.89,35.89,0,0,0,690.67,736h720.94l103.27-475Zm723.1,403H719.73l-.72-3.36L647.92,333H1425.3Z" transform="translate(-405.12 -107.7)"/><path d="M655.61,707.61l-8.41-37.7,14.41,66h29.06A35.89,35.89,0,0,1,655.61,707.61Z" transform="translate(-405.12 -107.7)"/><path d="M1238,468.77H844a36,36,0,0,1,0-72H1238a36,36,0,1,1,0,72Z" transform="translate(-405.12 -107.7)"/><path d="M1194.26,608.89H887.76a36,36,0,1,1,0-72h306.5a36,36,0,0,1,0,72Z" transform="translate(-405.12 -107.7)"/><path d="M1251.18,972.3a101.7,101.7,0,1,1,101.69-101.7A101.84,101.84,0,0,1,1251.18,972.3Zm0-131.35a29.65,29.65,0,1,0,29.64,29.65A29.69,29.69,0,0,0,1251.18,841Z" transform="translate(-405.12 -107.7)"/><path d="M822.09,972.3A101.7,101.7,0,1,1,923.78,870.6,101.83,101.83,0,0,1,822.09,972.3Zm0-131.35a29.65,29.65,0,1,0,29.64,29.65A29.69,29.69,0,0,0,822.09,841Z" transform="translate(-405.12 -107.7)"/></svg>
</template>

<script>
export default {
  props: {
    fill: {
      type: String,
      required: false
    }
  }
}
</script>
